import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('circuito-app-token'),
    registers_avaibles: localStorage.getItem('registers_avaibles'),
  },
  mutations: {
    saveToken(state, value) {
      localStorage.setItem('circuito-app-token', JSON.stringify(value));
      state.token = localStorage.getItem('circuito-app-token');
    },
    deleteToken(state) {
      localStorage.setItem('circuito-app-token', null);
      state.token = localStorage.getItem('circuito-app-token');
    },
    saveRegistersAvaibles(state, value) {
      localStorage.setItem('registers_avaibles', value);
      state.registers_avaibles = localStorage.getItem('registers_avaibles');
    },
    deleteRegistersAvaibles(state) {
      localStorage.setItem('registers_avaibles', null);
      state.registers_avaibles = localStorage.getItem('registers_avaibles');
    },
  },
  getters: {
    getUser: (state) => {
      let user = JSON.parse(state.token);

      return user;
    },
    getRegistersAvaibles: (state) => {
      let registers =  state.registers_avaibles;

      return registers;
    },
    isMobile: (state) => {
      return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
      );
    }
  },
  actions: {
  },
  modules: {
  }
})
