"use strict";

import Vue from 'vue';
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
//axios.defaults.baseURL = "https://localhost:44354/api/";
axios.defaults.baseURL = "https://app-sandbox.mifiel.com/api/v1/";
//axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
axios.defaults.headers.common['Authorization'] = 'Basic ZDg1M2Q5Y2M1NDYyZmMxODcxMGFhZTc3YzRkODBhN2NjNmE4ZGE2NTo5S1ZpWDNpNzg5V0xMVU5xZnJndytERWRxWml3WDZjN2xYQkFSd3J4TWQ2a2JFV0QxYkVPYUtlTElFK0hxUjFYUVpQT0pWS0Zvald2NVZkbGN3a0VOdz09';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

let config = {
  //baseURL: "https://localhost:44354/api/",
  // timeout: 60 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
