import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '.././store'

Vue.use(VueRouter)

const router = new VueRouter({
  //mode: 'history',
  //base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      name: 'NoFound',
      component: () => import('@/views/NoFound.vue')
    },
    {
      path: '/',
      redirect: '/session'
    },
    {
      path: '/session',
      name: 'Session',
      component: () => import('@/views/Session.vue')
    },
    {
      path: '/frame',
      name: 'Frame',
      component: () => store.getters.isMobile === null ? import('@/views/WebFrame.vue') : import('@/views/MovilFrame.vue'),
      meta: {
        authenticated: true
      },
      children: [
        //REGISTERS
        {
          path: '/register/index',
          name: 'RegisterIndex',
          component: () => import('@/views/register/Index.vue'),
          meta: {
            authenticated: true
          }
        },
        {
          path: '/register/index/my',
          name: 'RegisterIndex',
          component: () => import('@/views/register/MyIndex.vue'),
          meta: {
            authenticated: true
          }
        },
        {
          path: '/register/view/:id',
          name: 'RegisterView',
          component: () => import('@/views/register/View.vue'),
          meta: {
            authenticated: true
          }
        },
        {
          path: '/register/new',
          name: 'RegisterNew',
          component: () => import('@/views/register/New.vue'),
          meta: {
            authenticated: true
          }
        },
        //USERS
        {
          path: '/user/index',
          name: 'UserIndex',
          component: () => import('@/views/user/Index.vue'),
          meta: {
            authenticated: true
          }
        },
        {
          path: '/user/view/:id',
          name: 'UserView',
          component: () => import('@/views/user/View.vue'),
          meta: {
            authenticated: true
          }
        },
        {
          path: '/user/edit/:id',
          name: 'UserEdit',
          component: () => import('@/views/user/Edit.vue'),
          meta: {
            authenticated: true
          }
        },
        {
          path: '/user/new',
          name: 'UserNew',
          component: () => import('@/views/user/New.vue'),
          meta: {
            authenticated: true
          }
        },

        //OPTIONS
        {
          path: '/option/index',
          name: 'OptionIndex',
          component: () => import('@/views/option/Index.vue'),
          meta: {
            authenticated: true
          }
        },
        {
          path: '/option/about',
          name: 'OptionAbout',
          component: () => import('@/views/option/About.vue'),
          meta: {
            authenticated: true
          }
        },
        {
          path: '/option/close',
          name: 'OptionClose',
          component: () => import('@/views/option/Close.vue'),
          meta: {
            authenticated: true
          }
        },
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  let authenticated = to.matched.some(record => record.meta.authenticated);
  let user = store.getters.getUser;

  if(authenticated && !user){
    next('session');
  }else if(!authenticated && user){
    next('home');
  }else{
    next();
  }
});

export default router
